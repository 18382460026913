.main-home{
    background-color: rgb(255, 255, 255);
    height: 1000px;
    width: 100%;
}

.home-title{
    margin-left: 1%;
    border: 1px solid red;
    width: 40vw;
}
.main-home h3{


    font-size: 3vw;
}