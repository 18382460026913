.car-class {
    background-image: url('https://i0.wp.com/la-lista.com/wp-content/uploads/2022/11/happy-family-with-newborn-baby-2022-04-07-20-16-56-utc-1-1.jpg?w=1920&ssl=1');
    background-size: cover;
    background-position: center;
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
    padding: 20px;
    height: 1000px;
    position: relative;
    overflow: hidden;
    
    /* Asegura que el contenido esté por encima de la máscara azul */
  }

.table-title {
    display: flex;
    align-items: center;
    justify-content: center; 
    padding-bottom: 50px!important /* Espacio entre el título y la tabla */
  }

  .table-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;    
}

thead {
    color: orange;
}

.table-car {
    color: white;
  border-radius: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  padding-top: 3%;
  padding-bottom: 3%;
  background: rgba(171, 171, 172, 0.562);
  /* Green background with 30% opacity */
  position: relative;
  z-index: 2;
 

}

.table-title {
    margin-bottom: 20px; /* Espacio entre el título y la tabla */
  }

  .table-wrapper {
    overflow-x: auto; /* Añade desplazamiento horizontal si es necesario */
  }

.blue-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(8, 1, 85, 0.568);
    z-index: 0;
  }



.table-car th {
    font-size: xx-large;
    padding-bottom: 1vh;
    padding-left: 8vh;
    padding-right: 8vh;
    text-align: center;
}

.table-car td {
    font-weight: 500;
    text-align: center;
}