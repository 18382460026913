/* Estilos base */
head {
  display: flex;
}

nav {
  width: 100%;
  border-bottom: 5px solid white;
  font-weight: 600;
  height: 80px;
  background-color: #0e287c;
  color: #fff;
  padding: 10px;
  display: flex;
  align-items: center;
}

nav a {
  font-size: 1.7vh;
  color: #fff;
  text-decoration: none;
  margin-right: 15px;
}

.logo {
  margin-right: 1%;
}

nav a:hover {
  text-decoration: underline;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

li {
  margin-right: 10px;
}

/* Estilos para el menú responsive */
.menu-icon {
  display: none; /* Ocultar el icono de menú por defecto */
  cursor: pointer;
  color: white;
  font-size: 35px;
  position: fixed; /* Fijar el icono en la pantalla */
  right: 40px; /* Ajustar la posición desde el lado derecho */
}

@media (max-width: 768px) {
  .menu-icon {
    display: block; /* Mostrar el icono de menú en dispositivos pequeños */
  }
  nav{
    height: 50px;
  }
  ul {
    display: none; /* Ocultar la lista de navegación por defecto en dispositivos pequeños */
    flex-direction: column;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    background-color: #0e287c;
    z-index: 1;
    padding: 10px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
  }

  ul.active {
    display: flex; /* Mostrar la lista de navegación cuando el menú está abierto */
  }

  li {
    margin-right: 0; /* Eliminar el margen derecho en dispositivos pequeños */
    margin-bottom: 10px;
  }
}
